<template>
	<layout-div>
		<div class="card">
			<div class="card-header">
				<div class="btn-group">
					<router-link to="/admin/Main/create" class="btn btn-primary">新增</router-link>
					<button @click="showfilterForm()" class="btn btn-secondary">搜尋</button>
				</div>
			</div>
			<div class="card-body">
				<div class="row">
					<div class="col-sm-12">
						<table class="table table-bordered">
							<thead>
								<tr>
									<th v-for="header in headers" :key="header.id">
										<a href="#" @click="sort(header.id);">
											{{ header.label }}
											<HeaderIcon :caseby=header.icon />
										</a>
									</th>
									<th width="240px">管理</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="item in model" :key="item.housecode">
									<td>{{ item.housecode }}</td>
									<td>{{ item.customer }}</td>
									<td>{{ item.city }}</td>
									<td>{{ item.mobile }}</td>
									<td>{{ item.localfone }}</td>
									<td>{{ item.inverters }}</td>
									<td>{{ item.contract }}</td>
									<td>{{ item.uuse }}</td>
									<td>{{ item.dt2 }}</td>

									<td>
										<router-link :to="`/admin/Main/edit/${item.editKey}`"
											class="btn btn-outline-success mx-1">編輯</router-link>
										<router-link :to="`/admin/document/index/${item.editKey}`"
											class="btn btn-outline-success mx-1">送件文件</router-link>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>

				<pagination :total=this.total :filterTotal=this.filterTotal :CurrentPage=this.pagination.page
					@changeCallback="onPageChange" />

			</div>
		</div>
	</layout-div>
	<div class="modal fade" id="filterModal">
		<div class="modal-dialog modal-lg">
			<div class="modal-content">
				<div class="modal-header">
					<h4 class="modal-title">搜尋</h4>
					<button type="button" class="close" data-dismiss="modal" aria-label="Close">
						<span aria-hidden="true">&times;</span>
					</button>
				</div>
				<div class="modal-body">
					<div class="card">
						<form asp-action="Index" id="searchForm">
							<div class="card-body row">
								<div class="col-auto">
									<label class="control-label">案場代號</label>
									<input v-model="filter.housecode" id="housecode" class="form-control" />
								</div>
								<div class="col-auto">
									<label class="control-label">客戶姓名</label>
									<input v-model="filter.customer" id="customer" class="form-control" />
								</div>
								<div class="col-auto">
									<label class="control-label">區域</label>

									<select v-model="filter.city" class="form-control" id="city">
									<option value="" selected="">請選擇縣市</option>
										<option value="苗栗縣">苗栗縣</option>
										<option value="臺中市">臺中市</option>
										<option value="彰化縣">彰化縣</option>
										<option value="南投縣">南投縣</option>
										<option value="嘉義市">嘉義市</option>
										<option value="嘉義縣">嘉義縣</option>
										<option value="雲林縣">雲林縣</option>
										<option value="基隆市">基隆市</option>
										<option value="臺北市">臺北市</option>
										<option value="新北市">新北市</option>
										<option value="桃園市">桃園市</option>
										<option value="新竹市">新竹市</option>
										<option value="新竹縣">新竹縣</option>
										<option value="臺南市">臺南市</option>
										<option value="高雄市">高雄市</option>
										<option value="屏東縣">屏東縣</option>
										<option value="臺東縣">臺東縣</option>
										<option value="花蓮縣">花蓮縣</option>
										<option value="宜蘭縣">宜蘭縣</option>
										<option value="澎湖縣">澎湖縣</option>
										<option value="金門縣">金門縣</option>
										<option value="連江縣">連江縣</option>
									</select>
								</div>
								<div class="col-auto">
									<label class="control-label">合約狀態</label>
									<input v-model="filter.contract" id="contract" class="form-control" />
								</div>
								<div class="col-auto">
									<label class="control-label">使用DTU</label>
									<input v-model="filter.uuse" id="uuse" class="form-control" />
								</div>

							</div>
						</form>
					</div>
				</div>
				<div class="modal-footer justify-content-between">
					<button type="button" @click="reset()" class="btn btn-primary">清除</button>
					<button type="button" @click="fetchList()" class="btn btn-primary">開始搜尋</button>
					<button type="button" class="btn btn-default" data-dismiss="modal">關閉</button>
				</div>
			</div>
			<!-- /.modal-content -->
		</div>
		<!-- /.modal-dialog -->
	</div>
</template>

<script>
import $ from 'jquery'
import baseList from '@/views/baseList.vue'
import { listMain, deleteMain } from '@/api/Main.js'

export default {
	name: 'Mainlist',
	extends: baseList,
	data() {
		return {
			filter: {
				housecode: "",
				customer: "",
				city: "",
				contract: "",
				uuse: "",

			},
			pagination: {
				sortItem: "housecode",
				sortDir: "ASC",
				page: 1,
				pageSize: 50
			},
			headers: [
				{ id: 'housecode', label: "案場代號", icon: "▼" },
				{ id: 'customer', label: "客戶姓名", icon: "" },
				{ id: 'city', label: "區域", icon: "" },
				{ id: 'mobile', label: "客戶手機", icon: "" },
				{ id: 'localfone', label: "聯絡市話", icon: "" },
				{ id: 'inverters', label: "逆變器數量", icon: "" },
				{ id: 'contract', label: "合約狀態", icon: "" },
				{ id: 'uuse', label: "使用DTU", icon: "" },
				{ id: 'dt2', label: "掛錶時間", icon: "" },

			],
			model: [],
			total: 0,
			filterTotal: 0

		};
	},
	methods: {
		reset() {
			this.filter.housecode = "";
			this.filter.customer = "";
			this.filter.city = "";
			this.filter.contract = "";
			this.filter.uuse = "";

		},
		getRequest() {
			var result =
			{
				"filter": this.filter,
				"pagination": this.pagination
			};

			return result;
		},
		fetchList() {

			listMain(this.getRequest()).then(rep => {

				let sc = rep.data;
				this.total = sc.total;
				this.filterTotal = sc.filterTotal;
				this.model = sc.items;

				$("#filterModal").modal('hide');
			});
		},
		deleteRecord(id) {
			return deleteMain(id);
		},
		showfilterForm() {
			$("#filterModal").modal('show');
		}
	},
};
</script>
